<template>
  <div class="drop-down-container">
    <div class="text-box-wrapper">
      <input
        type="text"
        placeholder="Search Project"
        class="search-text-box"
        v-model="searchBox"
      />
    </div>
    <div class="projects-list">
      <div
        v-for="project in filteredProjects"
        :key="project.id"
        class="product-name"
        @click="handleViewProject(project.id)"
      >
        {{ project.project_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      required: true,
      type: Array,
    },
    closeDropDown: {
      required: true,
      type: Function,
    },
  },
  components: {},

  data: () => ({
    searchBox: "",
  }),

  created() {},
  methods: {
    handleViewProject(id) {
      if (this.$route.params.id !== id) {
        this.$router.replace({ ...this.$route, params: { id } });
      }
      this.closeDropDown();
    },
  },
  computed: {
    filteredProjects() {
      if (this.projects.length > 0) {
        let projects = JSON.parse(JSON.stringify(this.projects));
        return projects.filter((project) => {
          if (project.project_name) {
            return project.project_name
              .toUpperCase()
              .match(this.searchBox.toUpperCase());
          }
        });
      }
      return [];
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.drop-down-container {
  position: absolute;
  background: #ffffff;
  box-shadow: 5px 7px 30px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  width: 176px;
  padding: 16px 8px;
  text-align: left;
  right: -6px;
  top: 43px;
  z-index: 100;
}
.text-box-wrapper {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
}
.search-text-box {
  height: 35px;
  padding: 5px;
}
.search-text-box:focus {
  outline: none;
}
.projects-list {
  margin-top: 12px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}
.product-name {
  padding: 10px;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  cursor: pointer;
}
</style>
