var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drop-down-container" }, [
    _c("div", { staticClass: "text-box-wrapper" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchBox,
            expression: "searchBox",
          },
        ],
        staticClass: "search-text-box",
        attrs: { type: "text", placeholder: "Search Project" },
        domProps: { value: _vm.searchBox },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchBox = $event.target.value
          },
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "projects-list" },
      _vm._l(_vm.filteredProjects, function (project) {
        return _c(
          "div",
          {
            key: project.id,
            staticClass: "product-name",
            on: {
              click: function ($event) {
                return _vm.handleViewProject(project.id)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(project.project_name) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }